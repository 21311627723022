import { template } from "@ember/template-compiler";
import Component from "@glimmer/component";
import DButton from "discourse/components/d-button";
export default class PasskeyLoginButton extends Component {
    static{
        template(`
    <DButton
      @action={{@passkeyLogin}}
      @icon="user"
      @label="login.passkey.name"
      class="btn btn-social passkey-login-button"
    />
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
